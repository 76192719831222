import { useState } from "react";
import PropTypes from "prop-types";
import { InfoPopup } from "components/ui";

const ExcludedHcpcsField = ({
  setFieldValue,
  excluded_hcpcs,
  inactivation_reasons,
}) => {
  const [itemToAdd, setItemToAdd] = useState({
    hcpc: "",
    inactivation_reason: "",
  });

  return (
    <div
      style={{
        padding: 15,
        border: "1px solid #ccc",
      }}
    >
      <h1
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Excluded HCPCs
        <InfoPopup>
          Exclude rows that contain specific hcpc codes or choose an
          inactivation reason to deactivate patients with specific hcpc codes.
          To ignore importing rows with no hcpc code provided you can use value
          &quot;*BLANK&quot;
        </InfoPopup>
      </h1>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 100px",
          gridGap: 20,
        }}
      >
        <div>HCPCs</div>
        <div>Ignored/Inactivation Reason</div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 100px",
          gridGap: 20,
          padding: 20,
        }}
      >
        <input
          value={itemToAdd.hcpc}
          onChange={(e) => {
            console.log(e.target.value);
            setItemToAdd({ ...itemToAdd, hcpc: e.target.value });
          }}
        />
        <select
          value={itemToAdd.inactivation_reason}
          onChange={(e) => {
            setItemToAdd({ ...itemToAdd, inactivation_reason: e.target.value });
          }}
        >
          <option value="">IGNORED</option>
          <option disabled>-------------------------</option>
          {inactivation_reasons?.map((x) => (
            <option key={x.value} value={x.value}>
              {x.text}
            </option>
          ))}
        </select>
        <button
          type="button"
          disabled={!itemToAdd.hcpc}
          onClick={() => {
            setFieldValue("excluded_hcpcs", [itemToAdd, ...excluded_hcpcs]);
            setItemToAdd({ hcpc: "", inactivation_reason: "" });
          }}
        >
          Add
        </button>
      </div>
      <hr />
      <div
        style={{
          height: 300,
          overflowY: "auto",
          marginTop: 10,
        }}
      >
        {excluded_hcpcs.map((x) => (
          <div
            key={x.hcpc}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 100px",
              gridGap: 20,
              padding: 15,
              border: "1px solid #ccc",
            }}
          >
            <span>{x.hcpc}</span>
            <span>
              {!x.inactivation_reason
                ? "IGNORED"
                : inactivation_reasons?.find(
                    (y) => y.value == x.inactivation_reason,
                  )?.text}
            </span>
            <span>
              <button
                type="button"
                onClick={() => {
                  setFieldValue(
                    "excluded_hcpcs",
                    excluded_hcpcs?.filter((y) => y.hcpc !== x.hcpc),
                  );
                }}
              >
                Delete
              </button>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

ExcludedHcpcsField.defaultProps = {};

ExcludedHcpcsField.propTypes = {
  setFieldValue: PropTypes.func,
  excluded_hcpcs: PropTypes.arrayOf(
    PropTypes.shape({
      hcpc: PropTypes.string,
      inactivation_reason: PropTypes.string,
    }),
  ),
  inactivation_reasons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export default ExcludedHcpcsField;
