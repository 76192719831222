import { useState } from "react";
import PropTypes from "prop-types";
import { InfoPopup } from "components/ui";
import { Menu, Input } from "semantic-ui-react";

const DictionaryOptions = ({ options, dictionary, arrayHelpers }) => {
  const [search, setSearch] = useState("");
  return (
    <div
      style={{
        display: "flex",
        padding: 20,
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          gridGap: 10,
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <h2 style={{ margin: 0 }}>S3 Data Points</h2>
        <div
          style={{
            display: "flex",
            flex: 1,
            marginLeft: 30,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Input
            icon="search"
            placeholder="Search..."
            size="mini"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <Menu vertical fluid>
        {options
          ?.filter((x) => x.column.toUpperCase().includes(search.toUpperCase()))
          .map((x) => (
            <Menu.Item key={x.column}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "50px 1fr 20px",
                  fontSize: "1.4em",
                  gridGap: 10,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>
                  {!dictionary.some((y) => y.s3_data_point == x.column) && (
                    <button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          header: "",
                          s3_data_point: x.column,
                        })
                      }
                    >
                      +
                    </button>
                  )}
                </span>
                <span>{x.column}</span>
                <InfoPopup>{x.description}</InfoPopup>
              </div>
            </Menu.Item>
          ))}
      </Menu>
    </div>
  );
};

DictionaryOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  dictionary: PropTypes.arrayOf(PropTypes.shape({})),
  arrayHelpers: PropTypes.object,
};

export default DictionaryOptions;
