import { useState } from "react";
import PropTypes from "prop-types";
import useDragSort from "hooks/use-drag-sort";

const ListForm = ({
  fieldName,
  list,
  setFieldValue,
  label,
  options,
  sortable,
}) => {
  const [itemToAdd, setItemToAdd] = useState("");

  const { dragStart, dragEnter, sortOnDragEnd } = useDragSort();

  return (
    <div
      style={{
        padding: 10,
        border: "1px solid #ccc",
      }}
    >
      <h1
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {label}
      </h1>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 100px",
          gridGap: 20,
        }}
      >
        <select
          value={itemToAdd}
          onChange={(e) => {
            setItemToAdd(e.target.value);
          }}
        >
          <option />{" "}
          {options.map((x) => (
            <option
              key={x.id}
              value={x.id}
              disabled={list.some((id) => id == x.id)}
            >
              {x.name}
            </option>
          ))}
        </select>
        <button
          type="button"
          disabled={!itemToAdd}
          onClick={() => {
            setFieldValue(
              fieldName,
              sortable ? [...list, itemToAdd] : [itemToAdd, ...list]
            );
            setItemToAdd("");
          }}
        >
          Add
        </button>
      </div>
      <div
        style={{
          height: 300,
          overflowY: "auto",
          marginTop: 10,
        }}
      >
        {list.map((x, index) => (
          <div
            key={x}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 80px",
              gridGap: 20,
              padding: 5,
              border: "1px solid #ccc",
            }}
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragOver={(e) => e.preventDefault()}
            onDragEnd={() => {
              const newList = sortOnDragEnd(list);
              setFieldValue(fieldName, newList);
            }}
            draggable={sortable}
          >
            <span>
              {sortable && (
                <span style={{ paddingRight: 15, paddingLeft: 5 }}>
                  {index + 1}
                </span>
              )}
              {options?.find((y) => y.id == x)?.name}
            </span>
            <span>
              <button
                type="button"
                onClick={() => {
                  setFieldValue(
                    fieldName,
                    list?.filter((y) => y !== x)
                  );
                }}
              >
                Delete
              </button>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

ListForm.propTypes = {
  fieldName: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
  setFieldValue: PropTypes.func,
  label: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  sortable: PropTypes.bool,
};

export default ListForm;
