import React from "react";
import PropTypes from "prop-types";
import { Checkmark } from "components/ui";
import { formatDate } from "utils";
import { Table } from "semantic-ui-react";
import usePagination from "hooks/use-pagination";
import "./style.scss";

function GroupedPatientPreview({ data }) {
  const { curPageNum, totalPages, setPageNum, getPageData } = usePagination(
    data.result,
    100,
  );
  const patients = getPageData(curPageNum, data.result);
  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: 15,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 style={{ color: "#3498db" }}>
          {data?.importer_type} Import Preview -- Count: {data?.result?.length}
        </h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "80px 100px 80px",
            gridGap: 15,
            alignItems: "center",
          }}
        >
          <button
            onClick={() => {
              if (curPageNum <= 1) {
                setPageNum(totalPages);
              } else {
                setPageNum(curPageNum - 1);
              }
            }}
          >
            Back
          </button>
          <div>{`Page ${curPageNum} of ${totalPages}`}</div>
          <button
            onClick={() => {
              if (curPageNum >= totalPages) {
                setPageNum(1);
              } else {
                setPageNum(curPageNum + 1);
              }
            }}
          >
            Next
          </button>
        </div>
      </div>
      {patients.map((x) => (
        <div key={x[0].account_number} style={{ padding: 5 }}>
          <Table
            celled
            compact
            collapsing={false}
            striped
            className="compact-table"
            style={{
              margin: 0,
            }}
          >
            <Table.Header>
              <Table.Row></Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.HeaderCell>Account Number</Table.HeaderCell>
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>Suffix</Table.HeaderCell>
                <Table.HeaderCell>Branch</Table.HeaderCell>
                <Table.HeaderCell>DOB</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Service Date</Table.HeaderCell>
                <Table.HeaderCell>Open Order</Table.HeaderCell>
                <Table.HeaderCell>Setup Date</Table.HeaderCell>
                <Table.HeaderCell>Active</Table.HeaderCell>
                <Table.HeaderCell>Inactivation Reason</Table.HeaderCell>
                <Table.HeaderCell>Phone Number</Table.HeaderCell>
                <Table.HeaderCell>Mobile Phone Number</Table.HeaderCell>
                <Table.HeaderCell>Form Of Contact</Table.HeaderCell>
                <Table.HeaderCell>Contact Opt Out</Table.HeaderCell>
                <Table.HeaderCell>Street1</Table.HeaderCell>
                <Table.HeaderCell>Street2</Table.HeaderCell>
                <Table.HeaderCell>City</Table.HeaderCell>
                <Table.HeaderCell>State</Table.HeaderCell>
                <Table.HeaderCell>Zip</Table.HeaderCell>
                <Table.HeaderCell>Dr. Name</Table.HeaderCell>
                <Table.HeaderCell>Dr. NPI</Table.HeaderCell>
                <Table.HeaderCell>Dr. Phone</Table.HeaderCell>
                <Table.HeaderCell>Dr. Fax</Table.HeaderCell>
                <Table.HeaderCell>Dr. Street1</Table.HeaderCell>
                <Table.HeaderCell>Dr. City</Table.HeaderCell>
                <Table.HeaderCell>Dr. State</Table.HeaderCell>
                <Table.HeaderCell>Dr. Zip</Table.HeaderCell>
                <Table.HeaderCell>Item Name</Table.HeaderCell>
                <Table.HeaderCell>Item Description</Table.HeaderCell>
                <Table.HeaderCell>Item ID</Table.HeaderCell>
                <Table.HeaderCell>Item HCPC</Table.HeaderCell>
                <Table.HeaderCell>Item QTY</Table.HeaderCell>
                <Table.HeaderCell>Zero Billed</Table.HeaderCell>
                <Table.HeaderCell>Allowable</Table.HeaderCell>
                <Table.HeaderCell>Item Manufacturer</Table.HeaderCell>
                <Table.HeaderCell>Primary Ins Name</Table.HeaderCell>
                <Table.HeaderCell>Primary Ins Payor Id</Table.HeaderCell>
                <Table.HeaderCell>Primary Ins Group #</Table.HeaderCell>
                <Table.HeaderCell>Primary Ins Policy #</Table.HeaderCell>
                <Table.HeaderCell>Primary Ins Garantor</Table.HeaderCell>
                <Table.HeaderCell>Secondary Ins Name</Table.HeaderCell>
                <Table.HeaderCell>Secondary Ins Payor Id</Table.HeaderCell>
                <Table.HeaderCell>Secondary Ins Group #</Table.HeaderCell>
                <Table.HeaderCell>Secondary Ins Policy #</Table.HeaderCell>
                <Table.HeaderCell>Secondary Ins Garantor</Table.HeaderCell>
                <Table.HeaderCell>Deceased</Table.HeaderCell>
                <Table.HeaderCell>Delivery Note</Table.HeaderCell>
                <Table.HeaderCell>Auth Required</Table.HeaderCell>
                <Table.HeaderCell>Vent Patient</Table.HeaderCell>
                <Table.HeaderCell>F2F Expiration Date</Table.HeaderCell>
                <Table.HeaderCell>Base Line Sleep Study Date</Table.HeaderCell>
                <Table.HeaderCell>CMN Exp date</Table.HeaderCell>
                <Table.HeaderCell>CMN Status</Table.HeaderCell>
                <Table.HeaderCell>Speak Spanish</Table.HeaderCell>
                <Table.HeaderCell>Gender</Table.HeaderCell>
              </Table.Row>

              {x.map((row, index) => (
                <Table.Row key={`${row.account_number}_${index}`}>
                  <Table.Cell>{row.account_number}</Table.Cell>
                  <Table.Cell>{row.first_name}</Table.Cell>
                  <Table.Cell>{row.last_name}</Table.Cell>
                  <Table.Cell>{row.suffix}</Table.Cell>
                  <Table.Cell>{row.branch} {row.company_branch}</Table.Cell>
                  <Table.Cell>{formatDate(row.dob, "MM/DD/YYYY")}</Table.Cell>
                  <Table.Cell>{row.email}</Table.Cell>
                  <Table.Cell>
                    {formatDate(row.service_date, "MM/DD/YYYY")}
                  </Table.Cell>
                  <Table.Cell>
                    <Checkmark value={row.is_open_order} />
                  </Table.Cell>
                  <Table.Cell>
                    {formatDate(row.setup_date, "MM/DD/YYYY")}
                  </Table.Cell>
                  <Table.Cell>
                    <Checkmark value={row.active} />
                  </Table.Cell>
                  <Table.Cell>{row.inactivation_reason}</Table.Cell>
                  <Table.Cell>{row.phone_number}</Table.Cell>
                  <Table.Cell>{row.mobile_phone_number}</Table.Cell>
                  <Table.Cell>{row.form_of_contact}</Table.Cell>
                  <Table.Cell>
                    <Checkmark value={row.contact_opt_out} />
                  </Table.Cell>
                  <Table.Cell>{row.street1}</Table.Cell>
                  <Table.Cell>{row.street2}</Table.Cell>
                  <Table.Cell>{row.city}</Table.Cell>
                  <Table.Cell>{row.state}</Table.Cell>
                  <Table.Cell>{row.zip}</Table.Cell>
                  <Table.Cell>{row.doctor_full_name}</Table.Cell>
                  <Table.Cell>{row.doctor_npi}</Table.Cell>
                  <Table.Cell>{row.doctor_phone}</Table.Cell>
                  <Table.Cell>{row.doctor_fax}</Table.Cell>
                  <Table.Cell>{row.doctor_street1}</Table.Cell>
                  <Table.Cell>{row.doctor_city}</Table.Cell>
                  <Table.Cell>{row.doctor_state}</Table.Cell>
                  <Table.Cell>{row.doctor_zip}</Table.Cell>
                  <Table.Cell>{row.item_name}</Table.Cell>
                  <Table.Cell>{row.item_description}</Table.Cell>
                  <Table.Cell>{row.item_id}</Table.Cell>
                  <Table.Cell>{row.item_hcpc}</Table.Cell>
                  <Table.Cell>{row.item_qty}</Table.Cell>
                  <Table.Cell>
                    <Checkmark value={row.zero_billed} />
                  </Table.Cell>
                  <Table.Cell>{row.allowable}</Table.Cell>
                  <Table.Cell>{row.item_manufacturer}</Table.Cell>
                  <Table.Cell>{row.primary_insurance_name}</Table.Cell>
                  <Table.Cell>{row.primary_insurance_payor_id}</Table.Cell>
                  <Table.Cell>{row.primary_insurance_group_number}</Table.Cell>
                  <Table.Cell>{row.primary_insurance_policy_number}</Table.Cell>
                  <Table.Cell>{row.primary_insurance_guarantor}</Table.Cell>
                  <Table.Cell>{row.secondary_insurance_name}</Table.Cell>
                  <Table.Cell>{row.secondary_insurance_payor_id}</Table.Cell>
                  <Table.Cell>
                    {row.secondary_insurance_group_number}
                  </Table.Cell>
                  <Table.Cell>
                    {row.secondary_insurance_policy_number}
                  </Table.Cell>
                  <Table.Cell>{row.secondary_insurance_guarantor}</Table.Cell>
                  <Table.Cell>
                    <Checkmark value={row.deceased} />
                  </Table.Cell>
                  <Table.Cell>{row.delivery_note}</Table.Cell>
                  <Table.Cell>
                    <Checkmark value={row.authorization_required} />
                  </Table.Cell>
                  <Table.Cell>
                    <Checkmark value={row.ventilator_patient} />
                  </Table.Cell>
                  <Table.Cell>
                    {formatDate(row.face_2_face_expiration_date)}
                  </Table.Cell>
                  <Table.Cell>
                    {formatDate(row.base_line_sleep_study)}
                  </Table.Cell>
                  <Table.Cell>{formatDate(row.cmn_expiration_date)}</Table.Cell>
                  <Table.Cell>{row.cmn_status}</Table.Cell>
                  <Table.Cell>
                    <Checkmark value={row.speaks_spanish} />
                  </Table.Cell>
                  <Table.Cell>{row.gender}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      ))}
    </div>
  );
}

GroupedPatientPreview.propTypes = {
  data: PropTypes.shape({
    importer_type: PropTypes.string,
    result: PropTypes.arrayOf(PropTypes.array),
  }),
};

export default React.memo(GroupedPatientPreview);
