/*
  you probably do not want to commit changes to this file,
  you can set all the values in .env file to have this persisted and ignored in source control
  NOTE: changes to .env file will however require you to restart dev server
*/

export const API_BASE_URL = process.env.API_BASE_URL ?? "http://localhost:5166";
//"https://localhost:7223";

export const PHOENIX_URL = process.env.PHOENIX_URL ?? "https://sleepsolutionsandservices.com";
