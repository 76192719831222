import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import api from "api";

export const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const formOptionsQuery = useQuery("form-options", async () => {
    const response = await api.get(`/api/form-options`);
    return response.data;
  });

  return (
    <AppContext.Provider
      value={{
        companies: formOptionsQuery.data?.companies ?? [],
        header_options: formOptionsQuery.data?.header_options ?? {},
        form_of_contacts: formOptionsQuery.data?.form_of_contacts ?? [],
        importer_types: formOptionsQuery.data?.importer_types ?? [],
        inactivation_reasons: formOptionsQuery.data?.inactivation_reasons ?? [],
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
