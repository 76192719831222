import { useRef, useContext, useState } from "react";
import FileInput from "components/ui/file-input";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "app-provider";
import { Formik, Field, ErrorMessage } from "formik";
import { Button } from "semantic-ui-react";
import { Loading, InfoPopup } from "components/ui";
import PreviewImport from "./preview-import";
import { CompanyProfileContext } from "components/company-profile";
import Modal from "components/ui/modal";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import api from "api";

const StartImporterForm = () => {
  const fileRef = useRef();
  const companyFormOptions = useContext(CompanyProfileContext);

  const modalRef = useRef();
  const { companies } = useContext(AppContext);
  const { companyId } = useParams();
  const [previewData, setPreviewData] = useState();
  // const [openResultsModal, setOpenResultsModal] = useState(false);
  const navigate = useNavigate();

  const startImportMutation = useMutation(async (formData) => {
    const response = await api.post(`/api/import/start`, formData);
    toast.success("Import Started");
    navigate(`/history/${response.data}`);
    return response.data;
  });

  const previewImportMutation = useMutation(
    async (formData) => {
      const response = await api.post(`/api/import/preview`, formData);
      return response.data;
    },
    {
      onSuccess: (data) => {
        setPreviewData(data);
        // setOpenResultsModal(true);
        modalRef.current.open();
      },
    },
  );

  return (
    <div>
      <Formik
        initialValues={{
          file: null,
          importer_type: "",
          company_id: companyId ?? "",
          should_notify: true,
          use_system_user: true,
          initial_import: false,
          fulfillment_company: "",
          schedule_import: "",
        }}
        onSubmit={async (values) => {
          const formData = new FormData();
          formData.append("file", values.file);
          formData.append("company_id", values.company_id);
          formData.append("importer_type", values.importer_type);
          formData.append("initial_import", values.initial_import);
          formData.append("should_notify", values.should_notify);
          formData.append("schedule_import", values.schedule_import);
          formData.append("use_system_user", values.use_system_user);
          if (values.importer_type === "Fulfillment") {
            formData.append("fulfillment_company", values.fulfillment_company);
          }
          startImportMutation.mutate(formData);
        }}
        validationSchema={Yup.object().shape({
          importer_type: Yup.string().required("Required"),
          file: Yup.mixed().required("Required"),
        })}
      >
        {({ setFieldValue, handleSubmit, values, handleChange }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 10,
              margin: 10,
              alignItems: "center",
              fontSize: "1.5em",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                border: "1px solid #ccc",
                background: "#efefe",
                width: "100%",
                padding: 30,
                display: "grid",
                gridTemplateColumns: "auto",
                gridGap: 30,
              }}
            >
              <div>
                <label>Company</label>
                <select
                  name="company_id"
                  value={values.company_id}
                  onChange={handleChange}
                  style={{ marginLeft: 15 }}
                  disabled
                >
                  <option />
                  {companies.map((x) => (
                    <option key={x.value} value={x.value}>
                      {x.text}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <label style={{ paddingRight: 20 }}>Import Type</label>
                  <ErrorMessage
                    name="importer_type"
                    render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  />
                </div>
                <select
                  name="importer_type"
                  value={values.importer_type}
                  onChange={handleChange}
                  style={{ marginTop: 15 }}
                >
                  <option />
                  {companyFormOptions?.import_types?.map((x) => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 15,
                  }}
                >
                  <label style={{ paddingRight: 20 }}>File</label>
                  <ErrorMessage
                    name="file"
                    render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  />
                </div>
                <FileInput
                  ref={fileRef}
                  onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0]);
                  }}
                  onRemove={() => {
                    setFieldValue("file", null);
                  }}
                />
              </div>
              {values.importer_type === "Resupply" && (
                <label style={{ display: "flex", padding: 10 }}>
                  <Field type="checkbox" name="initial_import" />
                  <span style={{ paddingLeft: 10 }}>
                    Initial Import
                    <InfoPopup>
                      I don&apos;t really know what this does, but it is a thing
                      and should update this once I figure that out
                    </InfoPopup>
                  </span>
                </label>
              )}

              {values.importer_type === "Fulfillment" && (
                <div>
                  <label>Fulfillment Company</label>
                  <select
                    name="fulfillment_company"
                    value={values.fulfillment_company}
                    onChange={handleChange}
                    style={{ marginLeft: 15 }}
                  >
                    <option />
                    {companyFormOptions?.fulfillment_companies?.map((x) => (
                      <option key={x.value} value={x.value}>
                        {x.text}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <label style={{ display: "flex", padding: 10 }}>
                <Field type="checkbox" name="should_notify" />
                <span style={{ paddingLeft: 10 }}>Email me when completed</span>
              </label>
              <label style={{ display: "flex", padding: 10 }}>
                <Field type="checkbox" name="use_system_user" />
                <span style={{ paddingLeft: 10 }}>
                  Change logs - System Importer User
                  <InfoPopup>
                    Patient Change Logs will show the System Importer User as
                    the user who made the change instead of currently logged in
                    user
                  </InfoPopup>
                </span>
              </label>

              <div>
                <label style={{ paddingRight: 50 }}>
                  <span style={{ paddingRight: 10 }}>Schedule Import</span>
                  <InfoPopup>
                    Delay execution of import until specified date/time.
                  </InfoPopup>
                </label>
                <Field
                  type="datetime-local"
                  name="schedule_import"
                  min={new Date().toISOString().slice(0, -8)}
                />
                {values.schedule_import && (
                  <button onClick={() => setFieldValue("schedule_import", "")}>
                    Clear
                  </button>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <Button
                  type="button"
                  onClick={() => {
                    const formData = new FormData();
                    formData.append("file", values.file);
                    formData.append("company_id", values.company_id);
                    formData.append("importer_type", values.importer_type);
                    formData.append("initial_import", false);
                    formData.append("should_notify", values.should_notify);
                    formData.append("schedule_import", values.schedule_import);
                    if (values.importer_type === "Fulfillment") {
                      formData.append(
                        "fulfillment_company",
                        values.fulfillment_company,
                      );
                    }
                    previewImportMutation.mutate(formData);
                  }}
                >
                  Preview File
                </Button>
                <Button type="submit" onClick={handleSubmit}>
                  Start Importer
                </Button>
              </div>
            </div>
          </div>
        )}
      </Formik>
      {(previewImportMutation.isLoading || startImportMutation.isLoading) && (
        <Loading />
      )}

      <Modal ref={modalRef}>
        <PreviewImport data={previewData} />
      </Modal>
      {/* <Modal */}
      {/*   onClose={() => setOpenResultsModal(false)} */}
      {/*   onOpen={() => setOpenResultsModal(true)} */}
      {/*   open={openResultsModal} */}
      {/*   size="fullscreen" */}
      {/* > */}
      {/*   <Modal.Header> */}
      {/*     {previewData?.importer_type} Import Preview -- Lines:{" "} */}
      {/*     {previewData?.result?.length} */}
      {/*   </Modal.Header> */}
      {/*   <Modal.Content scrolling> */}
      {/*     <PreviewImport data={previewData} /> */}
      {/*   </Modal.Content> */}
      {/*   <Modal.Actions> */}
      {/*     <Button color="black" onClick={() => setOpenResultsModal(false)}> */}
      {/*       close */}
      {/*     </Button> */}
      {/*   </Modal.Actions> */}
      {/* </Modal> */}
    </div>
  );
};

export default StartImporterForm;
