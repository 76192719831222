import React from "react";
import { useQuery } from "react-query";
// import { Table } from "semantic-ui-react";
import api from "api";

function AppLogs() {
  const [date, setDate] = React.useState(new Date().toLocaleDateString("sv"));

  const query = useQuery(["logs", date], async ({ queryKey }) => {
    const [, date] = queryKey;
    const response = await api.get(`/api/logs?day=${date}`);
    console.log(response);
    return response.data; //.reverse();
  });

  // const [levelFilter, setLevelFilter] = React.useState("");
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="date"
          onChange={({ target }) => setDate(target.value)}
          value={date}
          style={{ marginRight: 30 }}
        />
        <button type="button" onClick={() => query.refetch()}>
          Refresh
        </button>
      </div>
      <div>
        <pre>{query.data}</pre>
      </div>
    </div>
  );
}

export default AppLogs;
