import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "components/ui";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

const maxPage = (count, per) => {
  const value = Math.ceil(count / per);
  return isFinite(value) ? value : 1;
};

const enumeratePages = (upperBound) => {
  const pageNums = new Array(upperBound);
  for (let i = 0; i < upperBound; ++i) {
    pageNums[i] = i + 1;
  }
  return pageNums;
};

const PageSelect = ({ page, count, setPage, per }) => {
  const totalPages = maxPage(count, per);
  return (
    <div className="page-select">
      <div className="previous-page">
        {page > 1 && (
          <IconButton
            title="Previous Page"
            onClick={() => setPage(page - 1)}
            icon={<MdNavigateBefore size={20} />}
            style={{ display: "flex", justifyContent: "center" }}
          />
        )}
      </div>
      <select
        value={page}
        onChange={({ target }) => setPage(Number(target.value))}
      >
        {enumeratePages(totalPages).map((num) => (
          <option key={num} value={num}>
            {num}
          </option>
        ))}
      </select>
      <div className="total-pages">of {totalPages}</div>
      <div className="next-page">
        {page < totalPages && (
          <IconButton
            title="Next Page"
            onClick={() => setPage(page + 1)}
            icon={<MdNavigateNext size={20} />}
            style={{ display: "flex", justifyContent: "center" }}
          />
        )}
      </div>
    </div>
  );
};

export default PageSelect;

PageSelect.propTypes = {
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  per: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setPage: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};
