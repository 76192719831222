import PropTypes from "prop-types";
import { useQuery } from "react-query";
import api from "api";
import { Loading } from "components/ui";
import { Button } from "semantic-ui-react";
import { formatDate } from "utils";
import { Link } from "react-router-dom";
import useLocalQuery, { Table } from "hooks/use-local-query";

const CompanyImportHistory = ({ companyId }) => {
  const importRecords = useQuery([`import-history`, companyId], async () => {
    const response = await api.get(`/api/importhistory/company/${companyId}`);
    return response.data;
  });

  const { rows, query, queryDispatcher } = useLocalQuery(
    importRecords?.data ?? [],
    {
      sort: {},
      filters: {},
    },
  );

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 80px",
          flex: 1,
          padding: 20,
          width: "100%",
          alignItems: "center",
        }}
      >
        <h1>Import History </h1>
        <div>
          <Button
            style={{ marginRight: 100 }}
            onClick={() => importRecords.refetch()}
          >
            Refresh
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flex: 1,
        }}
      >
        {importRecords.isFetching && <Loading />}
        <Table
          query={query}
          queryDispatcher={queryDispatcher}
          rows={rows}
          rowKey="id"
          TableRow={(row) => (
            <tr>
              <td>
                <Link to={`/history/${row.id}`}>{row.status}</Link>
              </td>
              <td>{row.file_name}</td>
              <td>{row.status}</td>
              <td>{formatDate(row.date_started)}</td>
              <td>{formatDate(row.date_finished)}</td>
              <td>{row.started_by_name}</td>
              <td>{row.created_count}</td>
              <td>{row.updated_count}</td>
              <td>{row.excluded_count}</td>
              <td>{row.error_count}</td>
            </tr>
          )}
          columns={[
            {
              header: "Import Type",
              field: "import_type",
              filters: [
                { key: "", value: "", text: "" },
                { key: "Backorder", value: "Backorder", text: "Backorder" },
                { key: "Compliance", value: "Compliance", text: "Compliance" },
                {
                  key: "Demographics",
                  value: "Demographics",
                  text: "Demographics",
                },
                { key: "Resupply", value: "Resupply", text: "Resupply" },
              ],
              dropdownPosition: "middle",
            },
            {
              header: "File Name",
              field: "file_name",
              style: { width: 450 },
            },
            {
              header: "Status",
              field: "status",
            },
            {
              header: "Start Time",
              field: "date_started",
            },
            {
              header: "Finish Time",
              field: "date_finished",
            },
            {
              header: "Start by",
              field: "started_by_name",
            },
            {
              header: "Created",
              field: "created_count",
            },
            {
              header: "Updated",
              field: "updated_count",
            },
            {
              header: "Excluded",
              field: "exclude_count",
            },
            {
              header: "Errors",
              field: "error_count",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default CompanyImportHistory;

CompanyImportHistory.propTypes = {
  companyId: PropTypes.string.isRequired,
};
