import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import ColumnFilter from "./column-filter";
import { hasFilter } from "hooks/use-local-query/helpers";
import { path } from "ramda";
import QueryTableContext from "../context";

const getSortIcon = (field, query) => {
  if (query?.sort?.by == field) {
    return path(["sort", "direction"], query) === "asc" ? (
      <MdArrowDropUp size={16} />
    ) : (
      <MdArrowDropDown size={16} />
    );
  }
};

const TableHeaderColumn = ({
  dropdownPosition,
  header,
  field,
  filters,
  filterKey,
  headerClassName,
  filterDownSelect,
  style,
  title
}) => {
  const { query, queryDispatcher } = useContext(QueryTableContext);
  const toggleSort = useCallback(() => {
    queryDispatcher({
      type: "toggleSort",
      by: field
    });
  }, [queryDispatcher, field]);
  return (
    <td
      className={`query-table-header-cell-container ${headerClassName}`}
      style={style}
      title={title}
    >
      <div className="query-table-header-cell">
        <div
          className={`header-text ${
            hasFilter(filterKey, query.filters) ? "active" : ""
          }`}
          onClick={toggleSort}
        >
          {header}
          <div className="sort-icon">{getSortIcon(field, query)}</div>
        </div>
        {!filters ? null : (
          <ColumnFilter
            filters={filters}
            filterKey={filterKey ? filterKey : field}
            query={query}
            queryDispatcher={queryDispatcher}
            dropdownPosition={dropdownPosition}
            filterDownSelect={filterDownSelect}
          />
        )}
      </div>
    </td>
  );
};

export default TableHeaderColumn;

TableHeaderColumn.propTypes = {
  field: PropTypes.string.isRequired,
  headerClassName: PropTypes.string,
  style: PropTypes.object,
  filters: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number
        ]),
        text: PropTypes.string
      })
    )
  ]),
  filterKey: PropTypes.string, // should be required if filters provided
  dropdownPosition: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  format: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  filterDownSelect: PropTypes.bool,
  title: PropTypes.string,
  SubFilter: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.func
  ])
};

TableHeaderColumn.defaultProps = {
  headerClassName: "",
  style: {},
  filterDownSelect: false,
  dropdownPosition: "left"
};

export const NonSortableHeader = ({ text, style, cellStyle }) => (
  <td className="query-table-header-cell-container" style={style}>
    <div className="query-table-header-cell" style={{ ...cellStyle, cursor: "default" }}>
      <div className={`header-text`}>{text}</div>
    </div>
  </td>
);

NonSortableHeader.defaultProps = { style: {} };
NonSortableHeader.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
  cellStyle: PropTypes.object,
};
