import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import api from "api";
import { useNavigate } from "react-router-dom";
import { Loading } from "components/ui";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const Login = () => {
  const navigate = useNavigate();
  const loginMutation = useMutation(
    async (payload) => {
      const response = await api.post(`/api/auth`, payload);
      return response.data;
    },
    {
      onSuccess: (data) => {
        Cookies.set("token", data.token);
        sessionStorage.setItem("token", data.token);
        navigate("/");
        location.reload(true);
      },
      onError: (error) => toast.error(error.message),
    },
  );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 200,
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2>Login</h2>
      {loginMutation.isLoading && <Loading />}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={async (values) => {
          loginMutation.mutate(values);
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required(),
          password: Yup.string().required(),
        })}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 10,
                margin: 10,
                alignItems: "center",
                fontSize: "1em",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  border: "1px solid #ccc",
                  background: "#efefe",
                  width: "100%",
                  minWidth: 400,
                  padding: 30,
                  display: "grid",
                  gridTemplateColumns: "auto",
                  gridGap: 30,
                }}
              >
                <div
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  <label style={{ padding: 5 }}>Email</label>
                  <Field name="email" type="email" />
                </div>
                <div
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  <label style={{ padding: 5 }}>Password</label>
                  <Field name="password" type="password" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button type="submit" style={{ width: "100%" }}>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
