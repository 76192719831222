import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { Field, useFormikContext, ErrorMessage } from "formik";
import useDragSort from "hooks/use-drag-sort";
import { columnOptions } from "utils";

const DictionaryList = ({ arrayHelpers }) => {
  const { values, setFieldValue } = useFormikContext();

  const { dragStart, dragEnter, sortOnDragEnd } = useDragSort();

  return (
    <div>
      <Table basic="very" celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {values.uses_headers ? "Header Name" : "Column Letter"}
            </Table.HeaderCell>
            <Table.HeaderCell>S3 Data Point</Table.HeaderCell>
            {/* <Table.HeaderCell>Allow Updates</Table.HeaderCell> */}
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {values.dictionary?.map((_, index) => (
            <Table.Row
              style={{
                margin: "10px 15%",
                textAlign: "center",
                fontSize: "20px",
              }}
              onDragStart={(e) => dragStart(e, index)}
              onDragEnter={(e) => dragEnter(e, index)}
              onDragOver={(e) => e.preventDefault()}
              onDragEnd={() => {
                const newList = sortOnDragEnd(values.dictionary);
                setFieldValue("dictionary", newList);
              }}
              key={index}
              draggable
            >
              <Table.Cell>
                <div>
                  <ErrorMessage
                    name={`dictionary.${index}.header`}
                    render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  />
                  {values.uses_headers ? (
                    <Field name={`dictionary.${index}.header`} />
                  ) : (
                    <Field name={`dictionary.${index}.header`} as="select">
                      {columnOptions.map((x) => (
                        <option key={x.value} value={x.value}>
                          {x.text}
                        </option>
                      ))}
                    </Field>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell>{values.dictionary[index].s3_data_point}</Table.Cell>
              {/* <Table.Cell> */}
              {/*   <Field */}
              {/*     type="checkbox" */}
              {/*     name={`dictionary.${index}.allow_updates`} */}
              {/*   /> */}
              {/* </Table.Cell> */}
              <Table.Cell>
                <button
                  type="button"
                  onClick={() => arrayHelpers.remove(index)}
                >
                  Delete
                </button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

DictionaryList.propTypes = { arrayHelpers: PropTypes.object.isRequired };

export default DictionaryList;
