import React, {
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import "./style.css";

function Modal({ children, fade = false, defaultOpened = false }, ref) {
  const [isOpen, setIsOpen] = useState(defaultOpened);

  const close = useCallback(() => setIsOpen(false), []);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close,
    }),
    [close],
  );

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) close();
    },
    [close],
  );

  useEffect(() => {
    if (isOpen) document.addEventListener("keydown", handleEscape, false);
    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  const modalRoot = document.getElementById("modal-root");

  if (!modalRoot) return null;

  return ref
    ? createPortal(
        isOpen ? (
          <div className={`modal ${fade ? "modal-fade" : ""}`}>
            <div className="modal-overlay" onClick={close} />
            <span
              role="button"
              className="modal-close"
              aria-label="close"
              onClick={close}
            >
              x
            </span>
            <div className="modal-body">{children}</div>
          </div>
        ) : null,
        modalRoot,
      )
    : null;
}
Modal.propTypes = {
  defaultOpened: PropTypes.bool,
  children: PropTypes.node.isRequired,
  fade: PropTypes.bool,
};

export default forwardRef(Modal);
