import React, { useState } from "react";
import PropTypes from "prop-types";
import { MdKeyboard, MdUnfoldMore } from "react-icons/md";

const PerPageSelect = ({ per, perPageLimit, perPageOptions, setPerPage }) => {
  const [perPageMode, setPerPageMode] = useState("select");

  const handlePer = ({ target: { value } }) => {
    const rowsPerPage = Math.abs(Math.round(parseInt(value)));
    if (Number.isInteger(rowsPerPage)) {
      setPerPage(rowsPerPage > perPageLimit ? perPageLimit : rowsPerPage);
    }
  };
  return (
    <div className="per-page-options-container">
      <label>Per Page:</label>
      {perPageMode === "input" ? (
        <input
          defaultValue={per}
          type="number"
          title={`Rows per page. limit: ${perPageLimit}`}
          onBlur={handlePer}
        />
      ) : (
        <select title="Rows per page" value={per} onChange={handlePer}>
          {perPageOptions.map(({ value, text }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </select>
      )}
      <span
        className="per-page-options-mode"
        title={
          perPageMode === "input"
            ? `Select Per Page Options`
            : "Input Per Page Options"
        }
        onClick={() => {
          setPerPageMode(() => (perPageMode === "select" ? "input" : "select"));
          // TODO input -> select then setPerPage, should really use some call back
          if (perPageMode === "input") setPerPage(perPageOptions[0].value);
        }}
      >
        {perPageMode === "select" ? <MdKeyboard /> : <MdUnfoldMore />}
      </span>
    </div>
  );
};

PerPageSelect.defaultProps = {
  perPageLimit: 1000,
};

PerPageSelect.propTypes = {
  per: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  perPageLimit: PropTypes.number,
  perPageOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setPerPage: PropTypes.func.isRequired,
};

export default PerPageSelect;
