import React, { useContext } from "react";
import { Tab } from "semantic-ui-react";
import { AppContext } from "app-provider";
import { ImporterForm, ResupplyImporterForm } from "components/importers";
import StartImportForm from "./start-import-form";
import api from "api";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Modal } from "components/ui";
import AddCompanyImporter from "./add-company-importer";
import CompanyImportHistory from "./company-import-history";

export const CompanyProfileContext = React.createContext();

const CompanyProfile = () => {
  const { companies } = useContext(AppContext);

  const { companyId } = useParams();

  const companyName = companies.find((x) => x.value == companyId)?.text;

  const companyFormOptions = useQuery([`form-options`, companyId], async () => {
    const response = await api.get(`/api/form-options/${companyId}`);
    return response.data;
  });

  const modalRef = React.useRef();

  return (
    <CompanyProfileContext.Provider
      value={{
        ...(companyFormOptions?.data ?? {}),
      }}
    >
      <div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr auto" }}>
          <h1>{companyName}</h1>
          <button onClick={() => modalRef.current.open()}>Add Importer</button>
        </div>
        <Tab
          panes={[
            {
              menuItem: "Importer",
              render: () => (
                <StartImportForm
                  companyId={companyId}
                  companyName={companyName}
                />
              ),
            },
            {
              menuItem: "History",
              render: () => (
                <CompanyImportHistory
                  companyId={companyId}
                  companyName={companyName}
                />
              ),
            },
            companyFormOptions.data?.import_types.includes("Compliance") && {
              menuItem: "Compliance",
              render: () => <ImporterForm importType="Compliance" />,
            },
            companyFormOptions.data?.import_types.includes("Resupply") && {
              menuItem: "Resupply",
              render: () => <ResupplyImporterForm />,
            },
            companyFormOptions.data?.import_types.includes("Snap") && {
              menuItem: "Snap",
              render: () => <ImporterForm importType="Snap" />,
            },
            companyFormOptions.data?.import_types.includes("Demographics") && {
              menuItem: "Demographics",
              render: () => <ImporterForm importType="Demographics" />,
            },
          ].filter(Boolean)}
          renderActiveOnly={true}
        />
        <Modal ref={modalRef} fade>
          <AddCompanyImporter
            closeModal={() => modalRef.current.close()}
            companyName={companyName}
            companyId={companyId}
            companyImportTypes={companyFormOptions.data?.import_types}
          />
        </Modal>
      </div>
    </CompanyProfileContext.Provider>
  );
};

export default CompanyProfile;
