import { Popup, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const InfoPopup = ({ children }) => {
  return <Popup content={children} trigger={<Icon name="info circle" />} />;
};

InfoPopup.propTypes = { children: PropTypes.string.isRequired };

export default InfoPopup;
