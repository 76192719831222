import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";

const FulfillmentPreview = ({ data }) => {
  return (
    <div>
      <h2 style={{ color: "#3498db" }}>
        {data?.importer_type} Import Preview -- Count: {data?.result?.length}
      </h2>
      {data.result.map((x) => (
        <div key={x.invoice_number} style={{ padding: 10 }}>
          <h5>{x[0]?.invoice_number}</h5>
          <Table celled key={x[0]?.invoice_number}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Invoice Number</Table.HeaderCell>
                <Table.HeaderCell>Ship Date</Table.HeaderCell>
                <Table.HeaderCell>Patient Acount Number</Table.HeaderCell>
                <Table.HeaderCell>Company</Table.HeaderCell>
                <Table.HeaderCell>Tracking</Table.HeaderCell>
                <Table.HeaderCell>Carrier</Table.HeaderCell>
                <Table.HeaderCell>Item ID</Table.HeaderCell>
                <Table.HeaderCell>Customer Account</Table.HeaderCell>
                <Table.HeaderCell>Sales Order Number</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {x.map((y, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{y.invoice_number}</Table.Cell>
                  <Table.Cell>{y.ship_date}</Table.Cell>
                  <Table.Cell>{y.patient_account}</Table.Cell>
                  <Table.Cell>{y.patient_company}</Table.Cell>
                  <Table.Cell>{y.tracking_number}</Table.Cell>
                  <Table.Cell>{y.carrier}</Table.Cell>
                  <Table.Cell>{y.item_id}</Table.Cell>
                  <Table.Cell>{y.customer_account}</Table.Cell>
                  <Table.Cell>{y.sales_order_number}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      ))}
    </div>
  );
};

FulfillmentPreview.propTypes = {
  data: PropTypes.shape({
    importer_type: PropTypes.string,
    fulfillment_company: PropTypes.string,
    result: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default FulfillmentPreview;
