import ErrorPage from "components/error-page";
import NotFoundPage from "components/not-found";
import CompanyList from "components/company-list";
import CompanyProfile from "components/company-profile";
import AppLayout from "components/layout";
import Login from "components/login";
import ImportRecords from "components/import-records";
import ImportLogs from "components/import-logs";
import ApplicationLogs from "components/app-logs";
import Info from "components/info";
import { AxiosInterceptor } from "api";

import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AxiosInterceptor>
        <AppLayout />
      </AxiosInterceptor>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <CompanyList />,
      },
      {
        path: "/companies/:companyId",
        element: <CompanyProfile />,
      },
      {
        path: "/history",
        element: <ImportRecords />,
      },
      {
        path: "/history/:importId",
        element: <ImportLogs />,
      },
      {
        path: "/app-logs",
        element: <ApplicationLogs />,
      },
      {
        path: "/info",
        element: <Info />,
      },
      {
        path: "/error",
        element: <ErrorPage />,
      },
      {
        path: "/not-found",
        element: <NotFoundPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
]);

export default router;
