import { QueryClient, QueryClientProvider } from "react-query";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import AppProvider from "./app-provider";
import AppRouter from "./router";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// if (process.env.NODE_ENV === "production")
//   Sentry.init({
//     dsn: "",
//     defaultIntegrations: false,
//     integrations: [
//       // new Integrations.BrowserTracing(),
//       new Sentry.Integrations.Breadcrumbs(),
//       new Sentry.Integrations.TryCatch(),
//       // new Sentry.Integrations.UserAgent(),
//     ],
//   });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      // staleTime: 60000,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <RouterProvider router={AppRouter} />
        <ToastContainer
          position="bottom-right"
          autoClose={4000}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          theme="dark"
        />
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;
