import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { Checkmark, DataTable } from "components/ui";

const InsuranceTable = ({ data }) => {
  return (
    <div>
      <h2 style={{ color: "#3498db" }}>
        {data?.importer_type} Import Preview -- Count: {data?.result?.length}
      </h2>
      <DataTable
        data={data.result}
        columns={[
          {
            header: "Insurnace Name",
            cell: (row) => row.insurance_name,
          },
          {
            header: "Payor ID",
            cell: (row) => row.payor_id,
          },
          {
            header: "Active",
            cell: (row) => <Checkmark value={row.active} />,
          },
          {
            header: "Excluded",
            cell: (row) => <Checkmark value={row.excluded} />,
          },
          {
            header: "Plan Type",
            cell: (row) => row.plan_type,
          },
          {
            header: "Capitated",
            cell: (row) => row.capitated,
          },
          {
            header: "allow_90_supply",
            cell: (row) => <Checkmark value={row.allow_90_supply} />,
          },
          {
            header: "compliance_required",
            cell: (row) => <Checkmark value={row.compliance_required} />,
          },
          {
            header: "traditional_medicare",
            cell: (row) => <Checkmark value={row.traditional_medicare} />,
          },
          {
            header: "medicare_survey",
            cell: (row) => <Checkmark value={row.medicare_survey} />,
          },
          {
            header: "yearly_doctor_appointment",
            cell: (row) => <Checkmark value={row.yearly_doctor_appointment} />,
          },
          {
            header: "return_unopened_supplies",
            cell: (row) => <Checkmark value={row.return_unopened_supplies} />,
          },
          {
            header: "annual_deductible_statement",
            cell: (row) => (
              <Checkmark value={row.annual_deductible_statement} />
            ),
          },
          {
            header: "counts_mask_as_seal",
            cell: (row) => <Checkmark value={row.counts_mask_as_seal} />,
          },

          {
            header: "cmn_required",
            cell: (row) => <Checkmark value={row.cmn_required} />,
          },
          {
            header: "ivr_autoship",
            cell: (row) => <Checkmark value={row.ivr_autoship} />,
          },
          {
            header: "ivrEducationStatement",
            cell: (row) => <Checkmark value={row.ivrEducationStatement} />,
          },
          {
            header: "Addl. Info",
            cell: (row) => row.additional_information,
          },
          {
            header: "Default Frequency",
            cell: (row) => row.default_frequency,
          },
        ]}
      >
        {(row) => (
          <Table.Row
            key={row.insurance_name + "hcpcs"}
            cells={0}
            textAlign="center"
          >
            {row.hcpcs.map((x, i) => (
              <Table.Cell key={`row${i}-col${x.hcpc}`} collapsing width={1}>
                <div>HCPC: {x.hcpc}</div>
                <div>Frequency: {x.frequency}</div>
                <div>Qty: {x.quantity}</div>
              </Table.Cell>
            ))}
          </Table.Row>
        )}
      </DataTable>
      {/* {data.result.map((x) => ( */}
      {/*   <div key={x.insurnace_name} style={{ padding: 10 }}> */}
      {/*     {JSON.stringify(x)} */}
      {/*   </div> */}
      {/* ))} */}
    </div>
  );
};

InsuranceTable.propTypes = {
  data: PropTypes.shape({
    importer_type: PropTypes.string,
    result: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default InsuranceTable;
