import { useContext, useState, useEffect } from "react";
import { AppContext } from "app-provider";
import { useNavigate } from "react-router-dom";
import { Input, Menu } from "semantic-ui-react";
import { useQueryClient } from "react-query";

const CompanyList = () => {
  const { companies } = useContext(AppContext);
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState("");
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries(["form-options"]);
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Menu vertical fluid>
        <Menu.Item>
          <Input
            icon="search"
            placeholder="Search..."
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
          />
        </Menu.Item>

        {companies
          .filter((x) =>
            filterValue.length > 0
              ? x.text.toLowerCase().includes(filterValue.toLowerCase())
              : true
          )
          .map((x) => (
            <Menu.Item
              key={x.value}
              onClick={() => navigate(`/companies/${x.value}`)}
            >
              {x.text}
            </Menu.Item>
          ))}
      </Menu>
    </div>
  );
};

CompanyList.propTypes = {};

export default CompanyList;
