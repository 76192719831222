import { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { API_BASE_URL } from "environment";
import { toast } from "react-toastify";

const axiosClient = axios.create({
  baseURL: API_BASE_URL,
  headers: { Authorization: `Bearer ${Cookies.get("token")}` },
});

export const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const responseInterceptor = axiosClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response?.status === 401) {
          navigate("/login");
        } else {
          toast.error(error.response?.data ?? error.message);
        }
        return Promise.reject(error);
      }
    );

    return () => axiosClient.interceptors.response.eject(responseInterceptor);
  }, [navigate]);

  // this doesnt work on page load
  // useEffect(() => {
  //   const requestInterceptor = axiosClient.interceptors.request.use(
  //     (req) => {
  //       req.headers["Authorization"] = `Bearer ${Cookies.get("token")}`;
  //       return req;
  //     },
  //     (error) => {
  //       return Promise.reject(error);
  //     }
  //   );
  //
  //   return () => axiosClient.interceptors.request.eject(requestInterceptor);
  // }, [navigate]);

  return children;
};

AxiosInterceptor.propTypes = { children: PropTypes.node };

export default axiosClient;
