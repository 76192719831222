import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

const IconButton = ({ icon, text }) => {
  return (
    <Button size="mini" icon>
      {icon}
      <span className="button-text">{text}</span>
    </Button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string,
};

export default IconButton;
