// import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import ErrorBoundary from "components/error-boundary";
import "semantic-ui-css/semantic.min.css";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
