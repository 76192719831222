import NavMenu from "components/nav-menu";
import { Outlet } from "react-router-dom";

const AppLayout = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <NavMenu />
      <div
        style={{
          padding: 10,
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <Outlet />
      </div>
      <div id="modal-root" />
    </div>
  );
};

export default AppLayout;
