import { useRef } from "react";

const useDragSort = () => {
  const dragItemRef = useRef();

  const dragOverItemRef = useRef();
  const dragStart = (_, position) => {
    dragItemRef.current = position;
  };

  const dragEnter = (_, position) => {
    dragOverItemRef.current = position;
  };

  const sortOnDragEnd = (list) => {
    const newList = [...list];
    const dragItemContent = newList[dragItemRef.current];
    newList.splice(dragItemRef.current, 1);
    newList.splice(dragOverItemRef.current, 0, dragItemContent);
    dragItemRef.current = null;
    dragOverItemRef.current = null;

    return newList;
  };

  return {
    dragStart,
    dragEnter,
    dragItem: dragItemRef,
    dragOverItem: dragOverItemRef,
    sortOnDragEnd,
  };
};

export default useDragSort;
