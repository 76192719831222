import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { API_BASE_URL } from "environment";
import Cookies from "js-cookie";

const NavMenu = () => {
  const [activeItem, setActiveItem] = React.useState("");
  const token = Cookies.get("token");
  return (
    <div
      style={{
        display: "grid",
        padding: 10,
        gridTemplateColumns: "1fr auto",
      }}
    >
      <Menu secondary>
        <Menu.Item
          as={NavLink}
          to="/"
          name="Companies"
          active={activeItem === "Companies"}
          onClick={() => setActiveItem("Companies")}
        />
        <Menu.Item
          as={NavLink}
          to="/history"
          name="history"
          active={activeItem === "history"}
          onClick={() => setActiveItem("history")}
        />
        <Menu.Item
          as={NavLink}
          to="/app-logs"
          name="Application Logs"
          active={activeItem === "Application Logs"}
          onClick={() => setActiveItem("Application Logs")}
        />
        <Menu.Item
          as={NavLink}
          to="/info"
          name="Info"
          active={activeItem === "Info"}
          onClick={() => setActiveItem("Info")}
        />
      </Menu>
      <div>
        <button
          onClick={() => {
            window.open(API_BASE_URL + `/hangfire`, "_blank", "noreferrer");
          }}
        >
          Hangfire/Server Info
        </button>
      </div>
    </div>
  );
};

export default NavMenu;
