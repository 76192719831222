import React, { useContext } from "react";
import PropTypes from "prop-types";
import { MdFilterList } from "react-icons/md";
import { FaFilter } from "react-icons/fa";
import { IconButton, Dropdown } from "components/ui";
import QueryTableContext from "../context";

const ColumnFilter = ({
  filterKey,
  filters,
  filterDownSelect,
  dropdownPosition,
}) => {
  const { query, queryDispatcher } = useContext(QueryTableContext);
  const selected = query?.filters[filterKey] ?? [];
  const [filterSearchValue, setFilterSearchValue] = React.useState("");

  function downSelectFilters({ text }) {
    return filterSearchValue.length > 0
      ? text.toLowerCase().includes(filterSearchValue.trim().toLowerCase())
      : true;
  }
  return (
    <Dropdown
      trigger={
        <IconButton
          icon={<MdFilterList />}
          className="button secondary icon-button column-filter-trigger"
        />
      }
      position={dropdownPosition}
    >
      <ul className="dropdown-list" onClick={(e) => e.stopPropagation()}>
        <li
          className="dropdown-command"
          onClick={() => {
            queryDispatcher({
              type: "clearFilter",
              by: filterKey,
            });
          }}
        >
          Clear Selections
        </li>
        <li
          className="dropdown-command"
          onClick={() => {
            const value = filters
              .filter(({ value }) => !selected.includes(value))
              .map(({ value }) => value);
            queryDispatcher({
              type: "setFilter",
              by: filterKey,
              value,
            });
          }}
        >
          Invert Selections
        </li>
        {filterDownSelect && (
          <li className="filter-downselect-input">
            <label>
              <FaFilter />
            </label>
            <input
              value={filterSearchValue}
              onChange={({ target }) => setFilterSearchValue(target.value)}
            />
          </li>
        )}
        {filters.filter(downSelectFilters).map(({ value, text }) => (
          <li
            key={value}
            className="dropdown-item"
            onClick={() => {
              queryDispatcher({
                type: "toggleFilter",
                by: filterKey,
                value,
              });
            }}
          >
            <input
              type="checkbox"
              checked={selected.includes(value)}
              readOnly
            />
            <label>{text}</label>
          </li>
        ))}
      </ul>
    </Dropdown>
  );
};

export default ColumnFilter;

ColumnFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object),
  query: PropTypes.object.isRequired,
  queryDispatcher: PropTypes.func.isRequired,
  filterDownSelect: PropTypes.bool,
  dropdownPosition: PropTypes.string,
};
