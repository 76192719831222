import { useNavigate, useLocation } from "react-router-dom";
import "./error-page.scss";

const ErrorPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const message = location?.state?.message ?? "";
  const subMessage = location?.state?.subMessage ?? "";
  const backTo = location?.state?.backTo;
  return (
    <div className="error-screen">
      <div className="error-container">
        <h1>Sorry</h1>
        <h3>{message}</h3>
        <h4 style={{ marginBottom: 20 }}>{subMessage}</h4>
        {backTo && (
          <button
            onClick={() => {
              navigate(backTo);
            }}
          >
            Go Back
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
