import PropTypes from "prop-types";
import { DataTable } from "components/ui";

const PatientTable = ({ data }) => {
  return (
    <div>
      <h2 style={{ color: "#3498db" }}>
        {data?.importer_type} Import Preview -- Count: {data?.result?.length}
      </h2>
      <DataTable
        data={data.result}
        columns={[
          {
            header: "Order Invoice Number",
            cell: (row) => row.order_invoice_number,
          },
          {
            header: "Item",
            cell: (row) => row.item,
          },
          {
            header: "Backorder Qty",
            cell: (row) => row.backorder_qty,
          },
          {
            header: "Expected Ship Date",
            cell: (row) => row.expected_order_date,
          },
          {
            header: "Voided",
            cell: (row) => row.voided,
          },
        ]}
      />
    </div>
  );
};

PatientTable.propTypes = {
  data: PropTypes.shape({
    importer_type: PropTypes.string,
    result: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default PatientTable;
