import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

const Checkmark = ({ value }) => {
  if (value === true) {
    return <Icon color="green" name="checkmark" />;
  } else if (value === false) {
    return <Icon color="red" name="close" />;
  }
  return null;
};

Checkmark.propTypes = { value: PropTypes.bool };

export default Checkmark;
