import { useState } from "react";
import PropTypes from "prop-types";

const CapitatedInsurancesField = ({
  setFieldValue,
  insurances,
  capitated_insurances,
}) => {
  const [itemToAdd, setItemToAdd] = useState({
    duration: "",
    id: "",
  });

  return (
    <div
      style={{
        padding: 15,
        border: "1px solid #ccc",
      }}
    >
      <h1
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Capitated Insurances
      </h1>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 100px",
          gridGap: 20,
        }}
      >
        <div>Insurance</div>
        <div>Duration</div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 100px",
          gridGap: 20,
          padding: 20,
        }}
      >
        <select
          value={itemToAdd.id}
          onChange={(e) => {
            setItemToAdd({ ...itemToAdd, id: e.target.value });
          }}
        >
          <option />
          {insurances?.map((x) => (
            <option key={x.id} value={x.id}>
              {x.name}
            </option>
          ))}
        </select>
        <input
          value={itemToAdd.duration}
          onChange={(e) => {
            console.log(e.target.value);
            setItemToAdd({ ...itemToAdd, duration: e.target.value });
          }}
        />
        <button
          type="button"
          disabled={!itemToAdd.duration}
          onClick={() => {
            setFieldValue("capitated_insurances", [
              itemToAdd,
              ...capitated_insurances,
            ]);
            setItemToAdd({ duration: "", id: "" });
          }}
        >
          Add
        </button>
      </div>
      <hr />
      <div
        style={{
          height: 300,
          overflowY: "auto",
          marginTop: 10,
        }}
      >
        {capitated_insurances.map((x) => (
          <div
            key={x.hcpc}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 100px",
              gridGap: 20,
              padding: 15,
              border: "1px solid #ccc",
            }}
          >
            <span>{insurances?.find((y) => y.id == x.id)?.name}</span>
            <span>{x.duration}</span>
            <span>
              <button
                type="button"
                onClick={() => {
                  setFieldValue(
                    "capitated_insurances",
                    capitated_insurances?.filter((y) => y.id !== x.id),
                  );
                }}
              >
                Delete
              </button>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

CapitatedInsurancesField.defaultProps = {};

CapitatedInsurancesField.propTypes = {
  setFieldValue: PropTypes.func,
  capitated_insurances: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.number,
      id: PropTypes.string,
    }),
  ),
  insurances: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
};

export default CapitatedInsurancesField;
