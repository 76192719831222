import React from "react";
import PropTypes from "prop-types";
import TableFooter from "./table-footer";
import TableHeader from "./table-header";
import { Loading } from "components/ui";
import { prop } from "ramda";
import QueryTableContext from "./context";
import "./style.scss";

const LocalQueryTable = ({
  query,
  controls,
  queryDispatcher,
  containerClass,
  columns,
  rows,
  isFetching,
  TableRow,
  renderFooter,
  usePagination,
  perPageOptions,
  count,
  rowKey,
  onRowChange
}) => (
  <QueryTableContext.Provider
    value={{
      query,
      queryDispatcher,
      rows
    }}
  >
    <div className="query-table-container">
      {controls && <div className="query-header-controls">{controls}</div>}
      <div className={containerClass}>
        {isFetching && (
          <div className="table-loading-spinner">
            <Loading size="50px" style={{ position: "sticky" }} />
          </div>
        )}
        <table className={`${isFetching ? "loading" : ""}`}>
          <TableHeader columns={columns} />
          <tbody className="body">
            {rows.map((row, index) => (
              <TableRow
                {...row}
                key={prop(rowKey, row)}
                index={index}
                onChange={onRowChange}
              />
            ))}
          </tbody>
        </table>
      </div>
      {renderFooter ? (
        <div className="query-footer">{renderFooter}</div>
      ) : (
        <TableFooter
          usePagination={usePagination}
          perPageOptions={perPageOptions}
          count={count}
          rowCount={rows.length}
        />
      )}
    </div>
  </QueryTableContext.Provider>
);

export default LocalQueryTable;

LocalQueryTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      filterKey: PropTypes.string,
      Cell: PropTypes.elementType,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
        })
      ),
      filterDownSelect: PropTypes.bool,
      style: PropTypes.object,
      title: PropTypes.string,
      SubFilter: PropTypes.elementType,
      headerClassName: PropTypes.string
    })
  ).isRequired,
  TableRow: PropTypes.elementType.isRequired,
  rowKey: PropTypes.string.isRequired,
  controls: PropTypes.node,
  containerClass: PropTypes.string,
  usePagination: PropTypes.bool,
  perPageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number
    })
  ),
  renderFooter: PropTypes.node,
  onRowChange: PropTypes.func,
  isColumnVisible: PropTypes.func,
  /* functions/properties from hook */
  query: PropTypes.object.isRequired,
  queryDispatcher: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    count: PropTypes.number,
    per: PropTypes.number
  }),
  count: PropTypes.number
};

LocalQueryTable.defaultProps = {
  containerClass: "query-table"
};
