import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

const getUserIdFromStorage = () => {
  try {
    const serializedUser = localStorage.getIten("persist:auth");
    const user = JSON.parse(serializedUser) || {};
    return user?.id;
  } catch (e) {
    return "";
  }
};

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node
  };
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setUser({
        id: getUserIdFromStorage()
      });
      scope.setTag("location", window.location.href);
      scope.setTag("errorBoundary", true);
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <h2 style={{ margin: "1rem", padding: "1rem", fontSize: 18 }}>
            Sorry, we seem to be having trouble
          </h2>
          <p style={{ margin: "1rem", padding: "1rem", fontSize: 16 }}>
            Our team has been notified.
          </p>
          <button onClick={() => Sentry.showReportDialog()}>
            Click here to send a detailed report
          </button>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
