import { useNavigate } from "react-router-dom";
import "./not-found.scss";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="not-found-screen">
      <div className="not-found-container">
        <h1>Sorry</h1>
        <h3>Page Not Found</h3>
        <button
          onClick={() => {
            navigate.goBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
