import PropTypes from "prop-types";
import GroupedPatientPreview from "./grouped-patient-preview";
import InsurancePreview from "./insurance-preview";
import OrderPreview from "./order-preview";
import FulfillmentPreview from "./fulfillment-preview";

const PreviewImport = ({ data }) => {
  return (
    <div>
      {(data.importer_type == "Resupply" ||
        data.importer_type == "Snap" ||
        data.importer_type == "Compliance" ||
        data.importer_type == "Demographics") && <GroupedPatientPreview data={data} />}

      {(data.importer_type == "Voided_Orders" ||
        data.importer_type == "Backorder") && <OrderPreview data={data} />}

      {data.importer_type == "Insurance" && <InsurancePreview data={data} />}

      {data.importer_type == "Fulfillment" && (
        <FulfillmentPreview data={data} />
      )}
    </div>
  );
};

PreviewImport.propTypes = { data: PropTypes.object };

export default PreviewImport;
