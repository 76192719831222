import { useQuery, useMutation } from "react-query";
import api from "api";
import { useParams, Link } from "react-router-dom";
import { formatDate } from "utils";
import { PHOENIX_URL } from "environment";
import useLocalQuery, { Table } from "hooks/use-local-query";
import { Button, Icon } from "semantic-ui-react";

const ImportLogs = () => {
  const { importId } = useParams();

  const importLogs = useQuery(
    [`import-logs`, importId],
    async () => {
      const response = await api.get(`/api/importhistory/${importId}`);
      return response.data;
    },
    {
      refetchInterval: 5000,
    },
  );

  const downloadMutation = useMutation(
    async (fileName) => {
      const response = await api({
        url: "/api/importhistory/download",
        method: "POST",
        responseType: "blob", // Important
        data: { fileName },
      });
      return response;
    },
    {
      onSuccess: (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", importLogs?.data?.file_name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      // onError: (error) => toast.error(error.message),
    },
  );
  const { rows, query, queryDispatcher } = useLocalQuery(
    importLogs?.data?.logs ?? [],
    {
      sort: {},
      filters: {},
    },
  );

  return (
    <div>
      <div
        style={{
          margin: 5,
          border: "1px solid #ccc",
        }}
      >
        <div
          style={{
            display: "grid",
            background: "#ddd",
            padding: 10,
            fontSize: 20,
            gridTemplateColumns: "repeat(3, 1fr) auto",
          }}
        >
          <div style={{ fontWeight: "bold" }}>
            Company:{" "}
            <span
              style={{
                paddingLeft: 10,
                color: "#118bcb",
              }}
            >
              <Link to={`/companies/${importLogs?.data?.company_id}`}>
                {importLogs?.data?.company_name}
              </Link>
            </span>
          </div>
          <div style={{ fontWeight: "bold" }}>
            Importer Type:{" "}
            <span
              style={{
                paddingLeft: 10,
                color: "#118bcb",
              }}
            >
              {importLogs?.data?.import_type}
            </span>
          </div>
          <div style={{ fontWeight: "bold" }}>
            Status:{" "}
            <span
              style={{
                paddingLeft: 10,
                color: "#118bcb",
              }}
            >
              {importLogs?.data?.status}
            </span>
          </div>
          <div>
            <button onClick={importLogs.refetch}> Refresh</button>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            padding: 15,
          }}
        >
          <div style={{ fontWeight: "bold" }}>File name</div>
          <div style={{ fontWeight: "bold" }}>Start Time</div>
          <div style={{ fontWeight: "bold" }}>Started By</div>
          <div style={{ fontWeight: "bold" }}>Finish Time</div>
          <div>
            <span style={{ marginRight: 10 }}>
              {importLogs?.data?.file_name}
            </span>

            <Button
              icon
              size="tiny"
              onClick={() => {
                downloadMutation.mutate(importLogs?.data?.file_name);
              }}
            >
              <Icon name="download" />
            </Button>
          </div>
          <div>{formatDate(importLogs?.data?.date_started)}</div>
          <div>{importLogs?.data?.started_by_name}</div>
          <div>{formatDate(importLogs?.data?.date_finished)}</div>
        </div>
        <hr />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            padding: 15,
          }}
        >
          <div style={{ fontWeight: "bold" }}>Total Patients</div>
          <div style={{ fontWeight: "bold" }}>Processed Patients</div>
          <div
            style={{
              fontWeight: "bold",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gridGap: 5,
            }}
          >
            <span style={{ color: "red" }}>Errors</span>
            <span style={{ color: "rgb(36, 159, 148)" }}>Created</span>
            <span style={{ color: "rgb(66, 129, 166)" }}>Updated</span>
            <span style={{ color: "#999" }}>Excluded</span>
          </div>

          <div>{importLogs?.data?.count}</div>
          <div>{importLogs?.data?.proccessed_count}</div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              justifyContent: "center",
              alignItems: "center",
              gridGap: 5,
            }}
          >
            <span style={{ color: "red" }}>
              {" "}
              {importLogs?.data?.error_count}
            </span>
            <span style={{ color: "rgb(36, 159, 148)" }}>
              {importLogs?.data?.created_count}{" "}
            </span>
            <span style={{ color: "rgb(66, 129, 166)" }}>
              {importLogs?.data?.updated_count}{" "}
            </span>
            <span style={{ color: "#999" }}>
              {" "}
              {importLogs?.data?.excluded_count}
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: 20,
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Table
          query={query}
          queryDispatcher={queryDispatcher}
          rows={rows}
          rowKey="id"
          TableRow={(row) => (
            <tr>
              <td>
                <a
                  href={`${PHOENIX_URL}/patients/${row.patient_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {row.patient_account}
                </a>
              </td>

              <td>{row.status}</td>
              <td>{row.error_msg}</td>
              <td>{row.note}</td>
            </tr>
          )}
          columns={[
            {
              header: "Patient Account Number",
              field: "patient_account",
            },
            {
              header: "Status",
              field: "status",
              filters: [
                { text: "Created", value: "Created" },
                { text: "Updated", value: "Updated" },
                { text: "Excluded", value: "Excluded" },
                { text: "Error", value: "Error" },
              ],
            },
            {
              header: "Error",
              field: "error_msg",
            },
            {
              header: "Note",
              field: "row.note",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ImportLogs;
