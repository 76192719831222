import { useState } from "react";
import PropTypes from "prop-types";
import api from "api";
import { Accordion, Icon } from "semantic-ui-react";
import { useQuery } from "react-query";

const Info = () => {
  const getStaticDictionaries = useQuery([`dictionaries`], async () => {
    const response = await api.get(`/api/dictionaries`);
    return response.data;
  });

  const [activeIndex, setActiveIndex] = useState();

  const handleClick = (_, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <div>
      <h1>Info</h1>
      <h3>File Headers</h3>
      <div>
        {[
          { title: "Compliance", dictionary: "compliance" },
          { title: "Backorder", dictionary: "backorder" },
          { title: "insurance", dictionary: "insurance" },
          { title: "fulfillment_cpap", dictionary: "fulfillment_cpap" },
          {
            title: "fulfillment_independence",
            dictionary: "fulfillment_independence",
          },
          { title: "fulfillment_xmed", dictionary: "fulfillment_xmed" },

          { title: "fulfillment_internal", dictionary: "fulfillment_internal" },
          { title: "fulfillment_mckesson", dictionary: "fulfillment_mckesson" },
          { title: "fulfillment_ppm", dictionary: "fulfillment_ppm" },
          { title: "fulfillment_vgm", dictionary: "fulfillment_vgm" },
          { title: "fulfillment_xmed", dictionary: "fulfillment_xmed" },
        ].map((x, index) => (
          <Dictionary
            key={x.title}
            title={x.title}
            dictionary={x.dictionary}
            activeIndex={activeIndex}
            index={index}
            data={getStaticDictionaries.data ?? {}}
            handleClick={handleClick}
          />
        ))}
      </div>
    </div>
  );
};

export default Info;

const Dictionary = ({
  title,
  dictionary,
  activeIndex,
  index,
  data,
  handleClick,
}) => {
  return (
    <Accordion>
      <Accordion.Title
        active={activeIndex === index}
        index={index}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        {title}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === index}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "300px 300px",
            padding: 10,
            margin: 10,
            alignItems: "center",
            border: "1px solid #bbb",
          }}
        >
          <div>
            <b>Column Header</b>
          </div>
          <div>
            <b>S3 Data Point</b>
          </div>
          {data[dictionary]?.map((item) => (
            <>
              <div>{item.column}</div>
              <div>{item.key}</div>
            </>
          ))}
        </div>
      </Accordion.Content>
    </Accordion>
  );
};
Dictionary.propTypes = {
  title: PropTypes.string,
  dictionary: PropTypes.string,
  activeIndex: PropTypes.number,
  index: PropTypes.number,
  data: PropTypes.object,
  handleClick: PropTypes.func,
};
