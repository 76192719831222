import { useContext, useState } from "react";
import PropTypes from "prop-types";
import api from "api";
import { useQueryClient, useMutation } from "react-query";
import { Loading } from "components/ui";
import { AppContext } from "app-provider";
import { toast } from "react-toastify";

const avaliableImportTypes = [
  "Resupply",
  "Backorder",
  "Demographics",
  "Compliance",
  "Snap",
  "Fulfillment",
  "Insurance",
];

const AddCompanyImporter = ({
  companyId,
  companyName,
  closeModal,
  companyImportTypes,
}) => {
  const queryClient = useQueryClient();
  const createCompanyImporter = useMutation(
    async ({ importType, companyTemplateId }) => {
      var result = await api.post(`/api/import-settings/${companyId}`, {
        importType,
        existingCompanyHeadersId: companyTemplateId,
      });
      return result;
    },
    {
      onSuccess: () => {
        toast.success("Created new importer");
        queryClient.invalidateQueries([`form-options`, companyId]);
      },
    }
  );

  const { companies } = useContext(AppContext);
  const [importType, setImportType] = useState("");
  const [companyTemplateId, setCompanyTemplateId] = useState("");

  return (
    <div style={{ color: "white" }}>
      <h2>Add new importer: {companyName}</h2>
      {(createCompanyImporter.isFetching ||
        createCompanyImporter.isLoading) && <Loading />}
      <div
        style={{
          padding: 50,
          margin: 20,
          display: "grid",
          gridGap: 50,
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <div>Import Type</div>
        <select
          value={importType}
          onChange={(e) => {
            setImportType(e.target.value);
          }}
        >
          <option />
          {avaliableImportTypes
            .filter((x) => !companyImportTypes?.includes(x))
            .map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
        </select>
        <div>Copy existing company headers</div>
        <select
          value={companyTemplateId}
          onChange={(e) => {
            setCompanyTemplateId(e.target.value);
          }}
        >
          <option />
          {companies.map((x) => (
            <option key={x.value} value={x.value}>
              {x.text}
            </option>
          ))}
        </select>
        <button
          disabled={!importType}
          onClick={async () => {
            if (
              confirm(
                `Are you sure you want to add a new importer for company: ${companyName}?`
              )
            ) {
              await createCompanyImporter.mutateAsync({
                importType,
                companyTemplateId,
              });
              setImportType("");
              closeModal();
            }
          }}
        >
          Add New Importer
        </button>
      </div>
    </div>
  );
};

AddCompanyImporter.propTypes = {
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  companyImportTypes: PropTypes.arrayOf(PropTypes.string),
  closeModal: PropTypes.func.isRequired,
};
export default AddCompanyImporter;
