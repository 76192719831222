import { useReducer, useEffect, useState } from "react";
import queryReducer from "./reducer";
import { applyQuery } from "./helpers";
export { default as Table } from "./table";
export { NonSortableHeader } from "./table-header/table-header-column";
import "./style.scss";

const useLocalQuery = (dataSource = [], initialQuery = {}) => {
  const [rows, setRows] = useState(dataSource);
  const [count, setCount] = useState(dataSource.length);
  const [totalPages, setTotalPages] = useState(0);
  const [query, queryDispatcher] = useReducer(queryReducer, initialQuery);
  const executeQuery = () => {
    const { page, totalPages, count: newCount } = applyQuery(dataSource, query);
    setRows(page);
    setTotalPages(totalPages);
    setCount(newCount);
  };
  useEffect(() => {
    executeQuery();
  }, [query, dataSource]);
  return {
    query,
    rows,
    queryDispatcher,
    totalPages,
    applyQuery: executeQuery,
    count
  };
};

export default useLocalQuery;
