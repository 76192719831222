import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";

function DataTable({ columns, data, children, ...props }) {
  return (
    <Table {...props}>
      <Table.Header>
        <Table.Row>
          {columns.map(({ header }, index) => (
            <Table.HeaderCell key={header + index}>{header}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((row, index) => (
          <>
            <Table.Row key={`${row.account_number}_${index}`}>
              {columns.map((c, i) => (
                <Table.Cell key={`${row.account_number}_${index}-col${i}`}>
                  {c.cell(row)}
                </Table.Cell>
              ))}
            </Table.Row>
            {typeof children == "function" ? children(row) : null}
          </>
        ))}
      </Table.Body>
    </Table>
  );
}

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cell: PropTypes.func.isRequired,
      header: PropTypes.string.isRequired,
    }),
  ),
};

export default DataTable;
